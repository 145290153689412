import { Keyboard, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/keyboard';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { ImageSlide, useModal } from '../index.js';

import css from './Carousel.module.scss';

function slideToImage(slide) {
    return {
        src: slide.backgroundSrc || slide.src,
        alt: '',
    };
}

const Carousel = (props) => {
    const { height, slides = [], ...restProps } = props;

    const { show } = useModal({ template: ImageSlide });

    const processedSlides = slides.filter((slide) => slide.backgroundSrc || slide.src)
        .map((slide) => {
            if (slide.backgroundSrc) {
                return {
                    tag: 'div',
                    clickToZoom: slide.clickToZoom,
                    style: {
                        backgroundImage: `url(${slide.backgroundSrc})`,
                        backgroundSize: `${slide.backgroundSize || 'cover'}`,
                        backgroundRepeat: `${slide.backgroundRepeat || 'no-repeat'}`,
                        backgroundPosition: `${slide.backgroundPosition || 'center'}`,
                        cursor: slide.clickToZoom ? 'pointer' : 'default',
                        minHeight: '500px',
                        ...slide.style,
                    }
                };
            } else {
                return { tag: 'img', ...slide };
            }
        });

    return (
        <Swiper
            autoHeight
            className={css.carousel}
            keyboard={{ enabled: true, onlyInViewport: true }}
            modules={[Keyboard, Navigation, Pagination]}
            navigation
            pagination={{ clickable: true }}
            style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
                "--swiper-pagination-bullet-inactive-color": "#ffcc00",
                "--swiper-pagination-bullet-inactive-opacity": "0.5",
                minHeight: height,
                height,
                width: '100%',
                maxWidth: '100vw'
            }}
            updateOnWindowResize
            rewind
            zoom
            {...restProps}
        >
            {processedSlides.map((slide, index) => {
                const onClick = (imageIndex) => {
                    // When opening in ImageSlide, we need to convert to an img
                    const images = slides.map((image) => slideToImage(image));
                    return slide.clickToZoom ? show({ images, src: images[imageIndex].src }) : null;
                };
                const classes = [css.slide, 'swiper-zoom-container'];
                const { clickToZoom, render, ...slideProps } = slide;

                return (<SwiperSlide key={`slide-${index}`}>
                    {slide.tag === 'div'
                        ? <div
                            className={classes.join(' ').trim()}
                            onClick={() => onClick(index)}
                            {...slideProps}
                        >
                            {render?.()}
                        </div>
                        : (
                            <img
                                alt={slide.alt}
                                className={classes.join(' ').trim()}
                                onClick={() => onClick(index)}
                                src={slide.src}
                                style={{ maxHeight: '80vh' }}
                            />
                        )
                    }
                </SwiperSlide>
                );
            })}
        </Swiper>
    );
};

export default Carousel;
